#svg-gradient {
  --color-stop-1: rgba(154, 74, 246, 1);
  --color-stop-2: rgba(75, 173, 244, 1);
}

.lost {
  svg.octopus {
    g {
      fill: url(#svg-gradient);
    }
  }
}
