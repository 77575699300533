@import '../../styles/variables.scss';

.shape-container {
  width: 240%;

  @media (min-width: theme('screens.md')) {
    width: 120%;
  }
  @media (min-width: theme('screens.lg')) {
    width: 100%;
  }
}

.corner-container {
  height: 105%;
}
