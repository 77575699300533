@import 'variables';

html {
  font-size: 12px;
  font-family: Poppins;
  font-weight: 300;
  height: 100%;
}

body {
  min-height: 100vh;
  width: 100%;
  background-color: #ffffff;
  margin: 0;
  color: #163648;
  height: 100%;
}

#root .app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 12px !important;

  h1 {
    font-weight: 700;
    font-size: 3rem;
  }

  h2 {
    font-weight: 500;
    font-size: 2.5rem;
  }

  h3 {
    font-weight: 400;
    font-size: 2rem;
  }

  h4 {
    font-weight: 400;
    font-size: 1.75rem;
  }

  h5 {
    font-weight: 400;
    font-size: 1.5rem;
  }

  h6 {
    font-weight: 400;
    font-size: 1.25rem;
  }

  .aside {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    background-color: #ffffff;
    border-right: 1px solid #e8e8e8;
  }

  .main {
    flex-grow: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .loader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #ffffffaa;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.stroke-1 {

  svg,
  path {
    stroke-width: 1 !important;
  }
}

.fill-white {
  fill: white;
}